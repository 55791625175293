import React from 'react';
import Layout from '@src/layouts';
import SEO from '@commons/SEO';

import {
  HeaderLayout,
  SectionUnderHeader,
  SingleTestimonial,
  ExpandableTextLinesBlocks,
  ButtonsInFewRows,
  BannerWithDownloadButton,
  SliderWithCaseStudiesCards,
} from './components';
import { TechStackWithDiagram } from '@commons/tech-stack-with-diagram/TechStackWithDiagram';

import { payloads } from './payloads';

export default function CaseStudyTemplate(props) {
  const payload = payloads[props.pageContext.pagePath];
  const {
    SEOProps,
    headerProps,
    sectionUnderHeaderProps,
    singleTestimonialProps,
    expandableTextLinesBlocksProps,
    buttonsInFewRowsProps,
    techStackWithDiagramProps,
    bannerWithDownloadButtonProps,
  } = payload;
  return (
    <Layout {...props}>
      <SEO {...SEOProps} />
      <HeaderLayout {...headerProps} displayTopBanner={false} />
      <SectionUnderHeader {...sectionUnderHeaderProps} />
      {singleTestimonialProps && <SingleTestimonial {...singleTestimonialProps} />}
      <ExpandableTextLinesBlocks {...expandableTextLinesBlocksProps} />
      <ButtonsInFewRows {...buttonsInFewRowsProps} />
      <TechStackWithDiagram {...techStackWithDiagramProps} />
      <BannerWithDownloadButton {...bannerWithDownloadButtonProps} />
      <SliderWithCaseStudiesCards />
    </Layout>
  );
}
