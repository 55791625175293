import { paths } from '@src/constants/pathsEnum';
import { featuredImageFluxNinja } from '@images/case-studies-pages';
import {
  go,
  typeScript,
  java,
  temporal,
  python,
  kubernetes,
  openTelemetry,
  istio,
  envoy,
  jsonnet,
} from '@images/logos-techstack-bigger';
import fluxNinja from '@images/logos-partners/FluxNinja-logo2.png';

export const fluxNinjaProps = {
  SEOProps: {
    title: 'Case Study | Intelligent Load Management for Cloud-Native Apps',
    description:
      'Learn how we assisted FluxNinja in developing an Intelligent Load Management system for cloud-native applications.',
    featuredImage: `https://codilime.com${featuredImageFluxNinja}`,
  },

  headerProps: {
    title: 'Development of Intelligent Load Management for Cloud-Native Apps',
  },

  sectionUnderHeaderProps: {
    text: 'FluxNinja is a cloud-native application reliability platform that provides a unified control layer for observability and control of microservices-based applications. The platform is designed to help platform and site reliability teams boost their productivity by automating reliability processes.',
    logo: {
      src: fluxNinja,
      alt: 'Flux Ninja',
    },
  },

  singleTestimonialProps: {
    testimonialCompany: 'FluxNinja',
  },

  expandableTextLinesBlocksProps: {
    subtitle:
      'The FluxNinja development team aimed to create a modern architecture for the Aperture open-source project. They wanted to build a platform that met the demands of a contemporary microservices-based application while incorporating advanced technical solutions, thus adding complexity to the project:',
    blocksData: {
      pointsList: [
        'Modern architecture: The developers sought a scalable, modular, and maintainable design, requiring research and implementation of the latest industry patterns and best practices.',
        'Advanced technical solutions: The team integrated innovative solutions to optimize performance, efficiency, and adaptability, exploring new algorithms, data structures, and technologies.',
      ],
      color: 'black',
    },
  },

  buttonsInFewRowsProps: {
    allButtonsData: [
      {
        buttonsInRowData: [
          { text: 'Frontend services', href: paths.SERVICES.FRONTEND },
          { text: 'Backend development', href: paths.SERVICES.BACKEND },
          { text: 'DevOps services', href: paths.SERVICES.DEVOPS },
        ],
        higherRow: true,
      },
    ],
  },

  techStackWithDiagramProps: {
    subtitle:
      'CodiLime collaborated with FluxNinja to help them create Aperture open-source software and FluxNinja ARC.\n',
    pointListIntro: 'To achieve it, the CodiLime team delivered:',
    pointList: [
      'Optimized OpenTelemetry pipelines that can process logs, metrics and traces',
      'Classifiers that operate on default request metadata and use the OPA Rego language',
      [
        'Different methods of using Aperture:',
        'as an Envoy filter in service mesh deployment',
        'as an SDK in multiple languages - Golang, Java, JavaScript/TypeScript, Python',
        'as a sidecar in virtual machine/local deployment',
      ],
      'UI design and development for FluxNinja ARC',
    ],
    slides: [go, typeScript, java, jsonnet, python, kubernetes, openTelemetry, istio, envoy, temporal],
  },

  bannerWithDownloadButtonProps: {
    buttonHref: 'https://resources.codilime.com/hubfs/case-studies/2023/case-study-fluxninja.pdf',
  },
};
