module.exports = {
  mesh7: 'building-a-microservices-security-platform-for-mesh7',
  nda: 'boosting-automated-validation-toolgates-in-big-data-system',
  captor: 'data-protection-secure-reliable-it-infrastructure-for-biotech-company',
  neptuneAi: 'building-cloud-native-application-for-data-science-lab',
  fluxNinja: 'development-of-intelligent-load-management-for-cloud-native-apps',
  nutanix: 'ci-cd-testing-for-xi-epoch',
  swift: 'accelerating-software-development-and-ci-cd-environments',
  procyon: 'development-of-next-generation-cloud-based-privileged-access-management-solution',
  nda2: 'building-api-sdks',
  telco: 'development-of-smart-manufacturer-platform',
  selector: 'ux-ui-portal-for-monitoring-data-visualization',
  selector2: 'ci-cd-process-design-and-improvements-for-selector-software-company',
};
