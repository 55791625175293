import React from 'react';
import { paths } from '@src/constants/pathsEnum';

import { featuredImageNDA2 } from '@images/case-studies-pages';
import {
  python,
  bash,
  go,
  docker,
  javaScript,
  dash,
  terraform,
  gRPC,
  aws,
  openAPI,
  azure,
  rest,
} from '@images/logos-techstack-bigger';
// import ndaLogo from '@images/logos-partners/NDA-SaaS-for-networks-logo.png';

export const nda2Props = {
  SEOProps: {
    title: 'Case Study | Building API SDKs, Terraform Providers, and more',
    description:
      'Discover how we built API SDKs, Terraform Providers, and automation tools for an infrastructure visibility platform. Read our case study.',
    featuredImage: `https://codilime.com${featuredImageNDA2}`,
  },

  headerProps: {
    title: 'Building API SDKs, Terraform Providers, and Automation Tools for Infrastructure Visibility Platform',
  },

  sectionUnderHeaderProps: {
    text: "The client is an internet-based enterprise that was established in the 2010s. The company's inception stemmed from the recognition that the networking landscape was in need of a scalable, robust, user-friendly, and cost-effective infrastructure visibility solution. Leveraging this insight, the company has created a comprehensive set of utilities that enable efficient monitoring, tracking, and alerting of network health, data volumes, expenses, and incidents.",
    // logo: {
    //   src: ndaLogo,
    //   alt: 'NDA SaaS for networks',
    // },
  },

  singleTestimonialProps: null,

  expandableTextLinesBlocksProps: {
    subtitle: (
      <>
        The challenge for this project was <b>to construct a suite of auxiliary utilities</b> that would facilitate and
        empower seamless onboarding of infrastructure onto the client&apos;s observability platform, integration with
        the furnished API, and instituting proprietary metric visibility tools.
        <br />
        <br />
        The most critical aspect of the project entailed the development of <b>API SDKs for Python and Go</b>, which
        would furnish developers with a consistent and concise interface while also shielding them from any changes that
        might impact the evolving REST and gRPC APIs.
      </>
    ),
    readMoreText: (
      <>
        Drawing upon the Go SDK, custom Terraform providers were created to enable the infrastructure as code approach
        to configuring services on the client&apos;s end.
        <br />
        <br />
        Using these Terraform providers, along with a certain amount of Python code, automated onboarding tools were
        established to facilitate <b>the effortless configuration and automatic onboarding</b> of hundreds of public
        cloud accounts into the platform for the benefit of the client&apos;s Customers.
        <br />
        <br />
        Furthermore, leveraging Python SDK, a reference web application was developed to visualize the SLA adherence
        based on the client’s synthetic mesh test data. The objective of this application is to offer the client’s
        customers a starting point for creating their visualization tools.
      </>
    ),
  },

  buttonsInFewRowsProps: {
    allButtonsData: [
      {
        buttonsInRowData: [
          { text: 'Frontend development', href: paths.SERVICES.FRONTEND },
          { text: 'Backend development', href: paths.SERVICES.BACKEND },
          { text: 'DevOps services', href: paths.SERVICES.DEVOPS },
        ],
        higherRow: true,
      },
    ],
  },

  techStackWithDiagramProps: {
    subtitle:
      'To achieve our client’s goal, we developed a suite of peripheral libraries, plugins, and scripts for the client’s platform.\n\n',
    pointListIntro: 'Our efforts have yielded the following results:',
    pointList: [
      'Improved accessibility and smooth operation of the REST and gRPC APIs while minimizing errors.',
      'Streamlined onboarding of customer infrastructure, significantly reducing the time and effort required for this task.',
      'Expedited the development of visualization tools for the client’s platform metrics, allowing for swift and efficient creation in a matter of hours.',
    ],
    slides: [python, bash, go, docker, javaScript, dash, terraform, gRPC, aws, openAPI, azure, rest],
  },

  bannerWithDownloadButtonProps: {
    buttonHref:
      'https://resources.codilime.com/hubfs/case-studies/2023/case-study-building-api-sdks-terraform-providers-and-automation-tools-for-infrastructure-visibility-platform-nda.pdf',
  },
};
