import { mesh7Props } from './mesh7';
import { ndaProps } from './nda';
import { captorProps } from './captor';
import { neptuneAiProps } from './neptuneAi';
import { fluxNinjaProps } from './fluxNinja';
import { nutanixProps } from './nutanix';
import { swiftProps } from './swift';
import { procyonProps } from './procyon';
import { nda2Props } from './nda2';
import { telcoProps } from './telco';
import { selectorProps } from './selector';
import { selector2Props } from './selector2';

import paths from './paths';

export const payloads = {
  [paths.mesh7]: mesh7Props,
  [paths.nda]: ndaProps,
  [paths.captor]: captorProps,
  [paths.neptuneAi]: neptuneAiProps,
  [paths.fluxNinja]: fluxNinjaProps,
  [paths.nutanix]: nutanixProps,
  [paths.swift]: swiftProps,
  [paths.procyon]: procyonProps,
  [paths.nda2]: nda2Props,
  [paths.telco]: telcoProps,
  [paths.selector]: selectorProps,
  [paths.selector2]: selector2Props,
};
