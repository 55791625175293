// extracted by mini-css-extract-plugin
export var companyName = "single-testimonial-module--company-name--b2b9e";
export var containerWithLogo = "single-testimonial-module--container-with-logo--a44aa";
export var innerContainerWithQuote = "single-testimonial-module--inner-container-with-quote--6cbf8";
export var innerWrapper = "single-testimonial-module--inner-wrapper--d9614";
export var mainTitle = "single-testimonial-module--main-title--2af03";
export var quote = "single-testimonial-module--quote--1496c";
export var quoteAuthor = "single-testimonial-module--quote-author--96b5d";
export var section = "single-testimonial-module--section--4332c";
export var sectionBackground = "single-testimonial-module--section-background--72242";
export var titlesContainer = "single-testimonial-module--titles-container--53a77";