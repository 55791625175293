import React from 'react';
import { paths } from '@src/constants/pathsEnum';

import { featuredImageNDA } from '@images/case-studies-pages';

import {
  gitHubActions,
  pytest,
  selenium,
  apacheJMeter,
  allureReport,
  greatExpectations,
} from '@images/logos-techstack-bigger';
// import ndaLogo from '@images/logos-partners/NDA-BigData-logo.png';

export const ndaProps = {
  SEOProps: {
    title: 'Case Study | Boosting the Validation Tollgates in a Big Data System',
    description:
      "See how we boosted automation for our partner's big data systems. We built a data platform with a configurable UI to support analytical activities.",
    featuredImage: `https://codilime.com${featuredImageNDA}`,
  },

  headerProps: {
    title: 'Boosting the Automated Validation Tollgates in a Big Data System',
  },

  sectionUnderHeaderProps: {
    text: 'In this project, we have been cooperating with the tech division of one of the world’s leading data-driven consulting companies. In line with the non-disclosure agreement, we will not disclose our client’s name.',
    // logo: {
    //   src: ndaLogo,
    //   alt: 'NDA BigData',
    // },
  },

  singleTestimonialProps: null,

  expandableTextLinesBlocksProps: {
    subtitle:
      'Our partnership focused on the two major business goals that our client needed to achieve during the project: ',
    blocksData: {
      pointsList: [
        <>
          <b>Building an extensible data platform</b> gathering information about companies worldwide and connecting the
          data relevant to each company from dozens of different data sources.
        </>,
        <>
          <b>Designing and implementing a configurable Web UI</b> (for both internal and external customers) for
          supporting analytical activities, building the decision funnel, browsing company details, and composing
          detailed comparison reports.
        </>,
      ],
      color: 'black',
    },
  },

  buttonsInFewRowsProps: {
    allButtonsData: [
      {
        buttonsInRowData: [
          { text: 'DevOps', href: paths.SERVICES.DEVOPS },
          { text: 'Test automation', href: paths.SERVICES.TEST_AUTOMATION },
        ],
      },
      {
        buttonsInRowData: [
          { text: 'Data engineering', href: paths.SERVICES.DATA_ENGINEERING },
          { text: 'UX/UI', href: paths.SERVICES.UX_UI_DESIGN },
        ],
      },
    ],
  },

  techStackWithDiagramProps: {
    subtitle:
      "Our team noticed that the root cause for most bugs and time spent on maintenance were issues related to the underlying data. We conducted workshops, which resulted in feedback that allowed us to successfully fulfill the project's requirements.\n\n",
    pointList: [
      'Implemented an engine able to execute tests gathered in many suites, written using different test frameworks.',
      'Integrated six different test sources developed by internal and external teams.',
      'Built a single source of truth and control center allowing for storage, maintenance, execution, and reporting of test case results.',
      'Introduced an Allure-based visual tool for the business stakeholders to monitor the application environments’ health in all environments.',
      'Set up automated toll gates at different stages of data processing to measure quality constraints and stop the process if they are not addressed.',
    ],
    slides: [gitHubActions, apacheJMeter, pytest, greatExpectations, selenium, allureReport],
  },

  bannerWithDownloadButtonProps: {
    buttonHref:
      'https://resources.codilime.com/hubfs/White%20papers%20and%20case%20studies/Case%20studies%202023/Case%20study_Boosting%20Automated%20Tollgates_NDA.pdf',
  },
};
