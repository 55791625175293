import React from 'react';
import { paths } from '@src/constants/pathsEnum';
import { featuredImageTelco } from '@images/case-studies-pages';
// import telcoLogo from '@images/logos-partners/Telco-logo.png';

export const telcoProps = {
  SEOProps: {
    title: 'Development of a Smart Manufacturer Platform|Case Study',
    description:
      'Read a case study of developing a smart manufacturing platform for a multinational telecommunications company and discover',
    featuredImage: `https://codilime.com${featuredImageTelco}`,
  },

  headerProps: {
    title: 'Development of a Smart Manufacturer Platform',
  },

  sectionUnderHeaderProps: {
    text: 'The client is a multinational telecommunications conglomerate, founded in the US and operating globally in areas including networks, mobile networks, broadband, digital media, and many more. ',
    // logo: {
    //   src: telcoLogo,
    //   alt: 'Telco',
    // },
  },

  singleTestimonialProps: null,

  expandableTextLinesBlocksProps: {
    subtitle: (
      <>
        The project&apos;s goal was to create a smart manufacturer platform including a UI portal responsible for
        management, monitoring & real-time visualization of conveyor belt robots, AMRs, smart racks, etc.
        <br />
        This platform is an innovative warehouse solution (intelligent warehouse) that automates the process of
        collecting and grouping items ordered by customers.
        <br />
        The solution includes:
      </>
    ),
    readMoreText: (
      <>
        <ul>
          <li>
            <b>Smart racks</b> (intelligent shelves) - temporary storage for items delivered from the warehouse. Smart
            racks are able to signal the sequence and location of items that should next be delivered to the conveyor
            belt.
          </li>
          <li>
            <b>Conveyor belt robots</b> with bins - conveyor belt robots can scan, transport and distribute items into
            specific subgroups within the bins. Bin subgroups are defined by jobs and orders.
          </li>
          <li>
            <b>Autonomous mobile robots</b> - AMRs (autonomous mobile robots) are responsible for transporting racks,
            delivering empty bins to the conveyor belt, and collecting filled bins with items where the set of collected
            items is defined by jobs and orders.
          </li>
        </ul>
        The smart manufacturer platform (SMP) UI portal is responsible for real-time visualization of the conveyor belt
        robots and the jobs / orders completion process. It tracks the states of any devices that are part of the SMP.
        It enables users to manage and monitor the entire process.
      </>
    ),
  },

  buttonsInFewRowsProps: {
    allButtonsData: [
      {
        buttonsInRowData: [
          { text: 'Frontend development', href: paths.SERVICES.FRONTEND },
          { text: 'UX services', href: paths.SERVICES.UX_UI_DESIGN },
        ],
      },
    ],
  },

  techStackWithDiagramProps: {
    subtitle: 'The primary goal of the CodiLime team was to create the SMP UI portal that allows users to:',
    pointList: [
      'list available items / devices located in the smart racks',
      'list jobs and orders with their completion status',
      'create new order configurations',
      'view smart racks with their content (items and their location on the rack)',
      "view AMRs' status",
      'monitor the work of conveyor belt robots in real time',
      'list and manage active and resolved alarms',
      'plan and monitor the sequence of jobs and orders and their completion process',
    ],
    pointListIntro: '',
  },

  bannerWithDownloadButtonProps: {
    buttonHref:
      'https://resources.codilime.com/hubfs/case-studies/2023/case-study-development-smart-manufacturer-platform.pdf',
  },
};
