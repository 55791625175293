// extracted by mini-css-extract-plugin
export var blackBackground = "texts-with-vertical-lines-module--black-background--b8a4e";
export var blackGradient = "texts-with-vertical-lines-module--black-gradient--54272";
export var blackLine = "texts-with-vertical-lines-module--black-line--4bd11";
export var blackLineTop = "texts-with-vertical-lines-module--black-line-top--cdabf";
export var blackText = "texts-with-vertical-lines-module--black-text--59141";
export var dot = "texts-with-vertical-lines-module--dot--a5d46";
export var dotTextWithList = "texts-with-vertical-lines-module--dot-text-with-list--f930c";
export var horizontalLine = "texts-with-vertical-lines-module--horizontal-line--96e1e";
export var horizontalLineTextWithList = "texts-with-vertical-lines-module--horizontal-line-text-with-list--124ef";
export var row = "texts-with-vertical-lines-module--row--8412d";
export var textLine = "texts-with-vertical-lines-module--text-line--cb270";
export var textListDotedWrapper = "texts-with-vertical-lines-module--text-list-doted-wrapper--f1310";
export var verticalLine = "texts-with-vertical-lines-module--vertical-line--26ee8";
export var verticalLineLast = "texts-with-vertical-lines-module--vertical-line-last--2f601";