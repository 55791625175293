import React from 'react';
import { paths } from '@src/constants/pathsEnum';
import { featuredImageSelector } from '@images/case-studies-pages';
import {
  figma,
  react,
  mobX,
  antDesign,
  d3JS,
  typeScript,
  highcharts,
  nest,
  mongoDB,
} from '@images/logos-techstack-bigger';
import selector from '@images/logos-partners/Selector-logo2.png';

export const selectorProps = {
  SEOProps: {
    title: 'Case Study | Creating UX & UI of a portal for monitoring data visualization',
    description:
      'Discover how we helped Selector build a monitoring data visualization portal. We collaborated to provide expertise in key areas.',
    featuredImage: `https://codilime.com${featuredImageSelector}`,
  },

  headerProps: {
    title: 'Creating UX & UI of a portal for monitoring data visualization',
  },

  sectionUnderHeaderProps: {
    text: "Selector Software is a software company that provides an AIOps platform for operational intelligence. Selector Software's analytics solution provides instant, actionable insights for companies managing their network and application infrastructures. It can help improve network performance and minimize costly downtime by reducing the time it takes to identify, detect, and resolve network issues.",
    logo: {
      src: selector,
      alt: 'Selector',
    },
  },

  singleTestimonialProps: {
    testimonialCompany: 'Selector',
  },

  expandableTextLinesBlocksProps: {
    subtitle:
      "The CodiLime team's mission was to create a visually captivating and user-friendly portal UI, tailored for the visualization of monitoring data collected by an AI-based real-time digital infrastructure monitoring platform.",
    readMoreText:
      'Through comprehensive research, the team analyzed industry benchmarks and aimed to select and propose optimal solutions to ensure that the data visualization would provide valuable insights and actionable information to users.',
  },

  buttonsInFewRowsProps: {
    allButtonsData: [
      {
        buttonsInRowData: [
          { text: 'Frontend services', href: paths.SERVICES.FRONTEND },
          { text: 'DevOps services', href: paths.SERVICES.DEVOPS },
          { text: 'UX design', href: paths.SERVICES.UX_UI_DESIGN },
        ],
        higherRow: true,
      },
    ],
  },

  techStackWithDiagramProps: {
    subtitle:
      'We gathered a highly skilled team of frontend engineers and UX designers with experience in working with information-heavy network monitoring environments. To provide our client with the highest quality services, including:',
    pointListIntro: '',
    pointList: [
      <>
        <b>A clickable UX prototype</b> that allowed users to engage with the design, enabling us to refine and enhance
        the user experience iteratively.
      </>,
      <>
        <b>Proper data visibility</b> empowering users to make informed decisions.
      </>,
      <>
        <b>Design and implementation</b> of the ultimate solution, aiming to integrate cutting-edge functionality with
        aesthetically pleasing visual elements.
      </>,
      <>
        <b>An iterative and agile approach</b> resulting in a solution meeting the highest quality and flexibility
        standards.
      </>,
      <>
        <b>Cooperation in short iteration loops</b>, actively seeking end users&apos; feedback to ensure the product
        exceeded their expectations. By incorporating user insights and preferences, we optimized the solution to align
        with their needs perfectly.
      </>,
    ],

    slides: [figma, react, mobX, antDesign, d3JS, typeScript, highcharts, nest, mongoDB],
  },

  bannerWithDownloadButtonProps: {
    buttonHref: 'https://resources.codilime.com/hubfs/case-studies/2023/case-study-selector-software-ux-ui.pdf',
  },
};
