import React from 'react';
import { paths } from '@src/constants/pathsEnum';
import { featuredImageSelector2 } from '@images/case-studies-pages';
import { kubernetes, argo, gitHubActions, jenkins, ansible, terraform } from '@images/logos-techstack-bigger';
import selector from '@images/logos-partners/Selector-logo2.png';

export const selector2Props = {
  SEOProps: {
    title: 'Case Study | Adopting platform engineering to improve the development process',
    description:
      'Discover how we helped Selector with adopting platform engineering to improve the development process. We collaborated to provide expertise in key areas.',
    featuredImage: `https://codilime.com${featuredImageSelector2}`,
  },

  headerProps: {
    title: 'Adopting platform engineering to improve the development process',
  },

  sectionUnderHeaderProps: {
    text: "Selector Software is a software company that provides an AIOps platform for operational intelligence. Selector Software's analytics solution provides instant, actionable insights for companies managing their network and application infrastructures. It can help improve network performance and minimize costly downtime by reducing the time it takes to identify, detect, and resolve network issues.",
    logo: {
      src: selector,
      alt: 'Selector',
    },
  },

  singleTestimonialProps: {
    testimonialCompany: 'Selector2',
  },

  expandableTextLinesBlocksProps: {
    subtitle:
      "CodiLime's team set out to improve the development processes to make them more reliable and easier to replicate. While the current procedures were functional, we recognized the need for greater robustness, especially for complex tasks. To address this, CodiLime's experts introduced a more structured framework to ensure consistency.",
    readMoreText:
      'At the same time, the team also focused on strengthening the infrastructure management to better protect against digital vulnerabilities. One of the priorities was to ensure the security of the environment.',
  },

  buttonsInFewRowsProps: {
    allButtonsData: [
      {
        buttonsInRowData: [
          { text: 'DevOps services', href: paths.SERVICES.DEVOPS },
          { text: 'Platform engineering', href: paths.SERVICES.PLATFORM_ENGINEERING },
        ],
      },
    ],
  },

  techStackWithDiagramProps: {
    subtitle:
      'The project scope contained an array of steps to optimize processes, bolster security, and attain critical certifications. The goals were:',
    pointListIntro: '',
    pointList: [
      <>
        <b>Pipelines for automated build, test and deployment processes.</b>
      </>,
      <>
        <b>Preparing application for deployment in public clouds</b> using Kubernetes Engine with the necessary tools
        and scenarios.
      </>,
      <>
        Implementing <b>automated backup-restore procedures</b> for deployments.
      </>,
      <>
        Design and implementation of a <b>secure continuous delivery mechanism</b> for customers.
      </>,
      <>
        <b>Providing tools for infrastructure management</b> located in public clouds like GCP, AWS, Azure and IBM
        Cloud.
      </>,
      <>Cooperation with customers to pass SoC and ISO27001 certification.</>,
    ],

    slides: [kubernetes, argo, gitHubActions, jenkins, ansible, terraform],
  },

  bannerWithDownloadButtonProps: {
    buttonHref:
      'https://resources.codilime.com/hubfs/case-studies/2023/case-study-selector-software-platform-engineering.pdf',
  },
};
