import React from 'react';
import { paths } from '@src/constants/pathsEnum';
import { featuredImageMesh7 } from '@images/case-studies-pages';
import { cPlusPlus, go, istio, kubernetes, envoy, angular } from '@images/logos-techstack-bigger';
import mesh7 from '@images/logos-partners/Mesh7-logo.png';

export const mesh7Props = {
  SEOProps: {
    title: 'Case Study | Building a microservices security platform for Mesh7',
    description:
      'Learn how CodiLime helped Mesh7 to build a microservices security platform. Discover how our network and DevOps services backed up Mesh7.',
    featuredImage: `https://codilime.com${featuredImageMesh7}`,
  },

  headerProps: {
    title: 'Building a microservices security platform for a disruptive startup',
  },

  sectionUnderHeaderProps: {
    text: 'Mesh7, a startup originating from Silicon Valley, emerged with a vision led by experienced professionals from the IT industry and received substantial support from venture capital funds. Mesh7 specialized in delivering the Cloud Native API Security Mesh solution, a cutting-edge approach to safeguarding contemporary applications designed for the DevSecOps framework. Recently, VMware recognized its potential and acquired the company.',
    logo: {
      src: mesh7,
      alt: 'mesh7',
    },
  },

  singleTestimonialProps: {
    testimonialCompany: 'Mesh7',
  },

  expandableTextLinesBlocksProps: {
    subtitle:
      'As an application’s complexity grows exponentially (resulting from interconnected ephemeral, heterogeneous, and distributed workloads), so too do concerns about the security of such applications. Using cloud and third-party services, and exchanging sensitive information at Layer 7 over a network,  had created dangerous blind spots:',
    blocksData: {
      pointsList: [
        'Lack of real-time visibility of the interactions between various workloads, cloud, and third-party services',
        'Lack of control over the flow of sensitive data, both internally and externally',
        'Impossible to detect anomalous behavior and unsanctioned changes in applications',
        'No real-time detection of lateral threats and vulnerabilities at run time.',
      ],
      color: 'black',
    },
    readMoreText: (
      <>
        Mesh7 decided to tackle these challenges by creating a complex platform to monitor communication between
        different workloads and apply automated security rules. The platform works on top of the customer’s
        infrastructure and ensures that no potential threat remains undetected.
        <br /> CodiLime was chosen as an external technology partner to help Mesh7 build the product and keep the
        promises it has made to its clients and investors. Together we have succeeded in creating a product we believe
        will become the first-in-class solution for ensuring the security of microservices.
      </>
    ),
  },

  buttonsInFewRowsProps: {
    allButtonsData: [
      {
        buttonsInRowData: [
          { text: 'DevOps services', href: paths.SERVICES.DEVOPS },
          { text: 'Frontend services', href: paths.SERVICES.FRONTEND },
          { text: 'Network services', href: paths.SERVICES.NETWORK_SOLUTION_SERVICES },
        ],
        higherRow: true,
      },
      {
        buttonsInRowData: [
          { text: 'Network security services', href: paths.SERVICES.SECURITY },
          { text: 'Test automation', href: paths.SERVICES.TEST_AUTOMATION },
        ],
      },
    ],
  },

  techStackWithDiagramProps: {
    subtitle:
      'Thanks to the SaaS model installation, it takes mere minutes to onboard a customer. The product can be used in any type of workload, cloud or environment. The modern user interface clearly visualizes all the necessary information.\n',
    pointList: [
      'Created DDOS protection mechanism',
      'Created a plethora of plugins for the observability ecosystem (service mesh, Istio)',
      'Created performance measurement toolki8',
      'Delivered policies generation mechanisms (api discovery, api validation, service discovery) utilizing Envoy Proxy as insertion point',
      'Developed data enrichment mechanisms and plugins for Envoy',
      'Golang control plane utilizing K8s operator',
    ],
    slides: [cPlusPlus, envoy, go, istio, kubernetes, angular],
  },

  bannerWithDownloadButtonProps: {
    buttonHref:
      'https://resources.codilime.com/hubfs/White%20papers%20and%20case%20studies/Case%20studies%202023/Case%20study_Mesh7.pdf',
  },
};
