// extracted by mini-css-extract-plugin
export var arrowStyles = "tech-stack-with-diagram-module--arrow-styles--d513b";
export var buttonTextContainer = "tech-stack-with-diagram-module--button-text-container--aa9c1";
export var detailsStyles = "tech-stack-with-diagram-module--details-styles--82a6b";
export var extraDescription = "tech-stack-with-diagram-module--extra-description--cc291";
export var hideElement = "tech-stack-with-diagram-module--hide-element--d3d1c";
export var imageStyles = "tech-stack-with-diagram-module--image-styles--00cc0";
export var innerWrapper = "tech-stack-with-diagram-module--inner-wrapper--43803";
export var lightTheme = "tech-stack-with-diagram-module--light-theme--f25c8";
export var pointsListIntro = "tech-stack-with-diagram-module--points-list-intro--f3426";
export var readMoreButton = "tech-stack-with-diagram-module--read-more-button--d8117";
export var rectangleStyles = "tech-stack-with-diagram-module--rectangle-styles--9e825";
export var section = "tech-stack-with-diagram-module--section--a90ee";
export var subtitle = "tech-stack-with-diagram-module--subtitle--d36c5";
export var title = "tech-stack-with-diagram-module--title--5e305";
export var titlesContainer = "tech-stack-with-diagram-module--titles-container--d1003";