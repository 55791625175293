import { paths } from '@src/constants/pathsEnum';
import { featuredImageCaptor } from '@images/case-studies-pages';

import { googleCloud, juniperNetworks, jiraServiceManagement } from '@images/logos-techstack-bigger';
import captorTherapeutics from '@images/logos-partners/Captor-Therapeutics2.png';

export const captorProps = {
  SEOProps: {
    title: 'Case Study | Data Protection and Secure, Reliable IT Infrastructure',
    description:
      'Learn how we ensured easy access to sensitive information for remote workers while improving backup solutions and user experience.',
    featuredImage: `https://codilime.com${featuredImageCaptor}`,
  },

  headerProps: {
    title: 'Data protection and secure, reliable IT infrastructure',
  },

  sectionUnderHeaderProps: {
    text: 'Captor Therapeutics is a biopharmaceutical company focused on the development of protein degradation drugs for cancer and autoimmune diseases which have limited or no known treatment options. Their integrated approach combines competencies in medicinal chemistry, molecular modeling, protein sciences, biophysics, and pharmacology.',
    logo: {
      src: captorTherapeutics,
      alt: 'Captor Therapeutics',
    },
  },

  singleTestimonialProps: {
    testimonialCompany: 'CaptorTherapeutics',
  },

  expandableTextLinesBlocksProps: {
    subtitle:
      "One of the project's key points was to ensure the high availability of data to Captor Therapeutics specialists in the most convenient and rapid way. This was connected with the company's need to manage vast amounts of computational data which requires in-depth analysis. The goal of the project was to:",
    blocksData: {
      pointsList: [
        'Secure and backup unprocessed information and all other network resources due to data sensitivity.',
        'Ensure easy access to data for remote workers. ',
        'Increase the speed and effectiveness of backup and cloud storage solutions as well as improve the user experience.',
      ],
      color: 'black',
    },
  },

  buttonsInFewRowsProps: {
    allButtonsData: [
      {
        buttonsInRowData: [
          { text: 'Network Professional Services', href: paths.SERVICES.NETWORK_PROFESSIONAL_SERVICES },
          { text: 'DevOps', href: paths.SERVICES.DEVOPS },
        ],
      },
    ],
  },

  techStackWithDiagramProps: {
    subtitle:
      'During our cooperation on the project, we focused on providing the best security standards for highly sensitive data, in line with current market standards.\n',
    pointListIntro: 'To achieve it, our CodiLime team:',
    pointList: [
      'Secured critical access to key platforms, using additional authentication steps where possible (2FA/MFA) for all administrative accounts, and giving proper documentation to the client.',
      'Secured wireless networks.',
      'Separated the location of workloads and their backups for additional data protection.',
      'Improved backup system for critical services – after changes, backup perform and restore is reduced to a few minutes.',
    ],
    slides: [googleCloud, juniperNetworks, jiraServiceManagement],
  },

  bannerWithDownloadButtonProps: {
    buttonHref:
      'https://resources.codilime.com/hubfs/White%20papers%20and%20case%20studies/Case%20studies%202023/Case%20study_Captor%20Therapeutics.pdf',
  },
};
