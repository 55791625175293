import React from 'react';
import { paths } from '@src/constants/pathsEnum';
import { featuredImageProcyon } from '@images/case-studies-pages';
import { go, gRPC, kubernetes, cloudBuild, typeScript, gitHub, publicClouds } from '@images/logos-techstack-bigger';
import procyon from '@images/logos-partners/procyon.svg';

export const procyonProps = {
  SEOProps: {
    title: 'Case Study | Developing a Next-gen Cloud-based PAM Solution',
    description:
      'Explore our case study on the development of a cloud-based PAM solution. Learn how Procyon tackled the challenge of managing privileged access.',
    featuredImage: `https://codilime.com${featuredImageProcyon}`,
  },

  headerProps: {
    title: 'Development of a next-generation cloud-based Privileged Access Management (PAM) solution',
  },

  sectionUnderHeaderProps: {
    text: 'Procyon is a startup founded in 2021 that offers a solution for infrastructure access without using static credentials. This privileged access management solution is designed for multi-cloud and API-based infrastructure, making it easy for enterprises to manage access to multiple resources by personal tokens or a self-service portal, streamlining the access approval process. Procyon also offers automated risk analysis for all permissions granted to all resources.',
    logo: {
      src: procyon,
      alt: 'Procyon',
    },
  },

  singleTestimonialProps: {
    testimonialCompany: 'Procyon',
  },

  expandableTextLinesBlocksProps: {
    subtitle:
      'Procyon faces the challenge of managing privileged access to expanding on-premise and multi-cloud services. Traditional access management solutions struggle to keep up with modern IT complexity with just enough privilege (JEP) and zero standing privilege (ZSP) principles on the rise.',
    readMoreText: (
      <>
        The widespread use of cloud services and blurred distinctions between privileged and standard users present
        difficulties for managing privileged accounts. It&apos;s crucial to manage a more granular set of resource
        credentials that meet strict security requirements and expire after use.
        <br />
        <br />
        Procyon leverages biometric technology and TPM cryptography to establish continuous authorization procedures for
        DevOps teams in multi-cloud development environments.
      </>
    ),
  },

  buttonsInFewRowsProps: {
    allButtonsData: [
      {
        buttonsInRowData: [{ text: 'Backend services', href: paths.SERVICES.BACKEND }],
      },
    ],
  },

  techStackWithDiagramProps: {
    subtitle:
      'The project scope reflects the ambitions of our partner towards their flagship product. We helped the Procyon team to create privileged access management software that enables:',
    pointListIntro: '',
    pointList: [
      'Discovering user services in three major cloud providers: AWS, GCP, Azure.',
      'Providing just-in-time access to resources.',
      'Approval requests to be accepted by an account administrator.',
      'A risk assessment tool that prevents credentials and privileges sprawl.',
    ],
    slides: [go, gRPC, kubernetes, cloudBuild, typeScript, gitHub, publicClouds],
  },

  bannerWithDownloadButtonProps: {
    buttonHref: 'https://resources.codilime.com/hubfs/case-studies/2023/case-study-procyon-ai.pdf',
  },
};
