import React from 'react';
import { paths } from '@src/constants/pathsEnum';
import { featuredImageNutanix } from '@images/case-studies-pages';
import { go, circleCI, python, spinnaker, aws, harness, googleCloud } from '@images/logos-techstack-bigger';
import nutanix from '@images/logos-partners/Nutanix-logo2.png';

export const nutanixProps = {
  SEOProps: {
    title: 'Case Study | CI/CD and Testing for Xi Epoch (now part of Prism)',
    description:
      'See how we tackled the challenge of building a CI/CD and testing for Xi Epoch. Explore our backend development, DevOps, and test automation solutions.',
    featuredImage: `https://codilime.com${featuredImageNutanix}`,
  },

  headerProps: {
    title: 'CI/CD and testing for Xi Epoch – design and development',
  },

  sectionUnderHeaderProps: {
    text: "Nutanix is a global leader in cloud software and hyperconverged infrastructure solutions. One of the company's products, Xi Epoch (now part of Prism), is a real-time observability tool for multi-cloud applications.",
    logo: {
      src: nutanix,
      alt: 'Nutanix',
    },
  },

  singleTestimonialProps: {
    testimonialCompany: 'Nutanix',
  },

  expandableTextLinesBlocksProps: {
    subtitle: (
      <>
        Xi Epoch (currently part of Prism) is a full-stack monitoring and alerting service for every enterprise
        application at any scale across public clouds and private infrastructure. It generates terabytes of metrics and
        integrates with over 200 common applications to holistically understand application performance. The system is
        operations-ready from the first launch.
        <br />
        <br />
        The challenge was to build a continuous integration system that would be easy to use and simple to develop for
        multiple engineers around the world.
      </>
    ),
  },

  buttonsInFewRowsProps: {
    allButtonsData: [
      {
        buttonsInRowData: [
          { text: 'Backend development', href: paths.SERVICES.BACKEND },
          { text: 'DevOps services', href: paths.SERVICES.DEVOPS },
          { text: 'Test automation', href: paths.SERVICES.TEST_AUTOMATION },
        ],
        higherRow: true,
      },
    ],
  },

  techStackWithDiagramProps: {
    subtitle: (
      <>
        The first stage of the project involved migrating CI workflows from Jenkins to CircleCI. This was achieved by
        methodically gathering requirements and creating reusable resources to improve build reproducibility and
        reliability.
        <br />
        <br />
        Changes to the toolchain brought corresponding changes to the build system’s structure and flow. Multiple stages
        have been separated to parallelize the work and speed up the process and make it as close to Agile and DevOps
        standards as possible.
      </>
    ),
    readMoreText: (
      <>
        Ultimately, faster feedback means the work is done more effectively. Using CircleCI empowered us to make the
        builds highly reproducible and improve the way we conducted them.
        <br />
        <br />
        The second stage involved preparing a scalable test automation framework that would be easy to integrate with
        the continuous integration system and enable developers to launch it locally against any built part of a system.
        This was achieved by mocking up every part of the system and using a containerized environment (Docker and
        Docker Compose) for the data aggregators and test execution.
      </>
    ),

    slides: [go, circleCI, python, spinnaker, aws, harness, googleCloud],
  },

  bannerWithDownloadButtonProps: {
    buttonHref: 'https://resources.codilime.com/hubfs/case-studies/2023/cs-nutanix.pdf',
  },
};
