import React from 'react';
import { featuredImageNeptune } from '@images/case-studies-pages';
import {
  ansible,
  docker,
  debian,
  kubernetes,
  aws,
  elasticStack,
  googleCloud,
  python,
  etcd,
  terraform,
  helm,
  git,
  ubuntu,
  typeScript,
  java,
  redux,
  react,
  javaScript,
  less,
  mooseFS,
  rxJS,
} from '@images/logos-techstack-bigger';
import neptune from '@images/logos-partners/Neptune-logo2.png';

export const neptuneAiProps = {
  SEOProps: {
    title: 'Case Study | Building a Cloud-native Application for a Data Lab',
    description:
      'Discover how we built a cloud-native application for a data science lab. Learn how we helped streamline the machine learning model development process.',
    featuredImage: `https://codilime.com${featuredImageNeptune}`,
  },

  headerProps: {
    title: 'Building a cloud-native application for a data science lab',
  },

  sectionUnderHeaderProps: {
    text: 'Neptune.ai started out as a cloud-native data science lab. Currently, Neptune’s mission is to give production ML teams the same level of control while building models as software developers have during application shipping.',
    logo: {
      src: neptune,
      alt: 'Neptune.ai',
    },
  },

  singleTestimonialProps: {
    testimonialCompany: 'NeptuneAi',
  },

  expandableTextLinesBlocksProps: {
    subtitle:
      'When starting this project, both the CodiLime and Neptune.ai teams were aware that building machine learning models requires massive computing power. To achieve that the correct selection of technologies and devices was crucial at this point.',
    readMoreText: (
      <>
        At the beginning, there was a spike in the demand for computing power. We were able to meet these requirements,
        and by the end they had dropped sharply.
        <br /> The CodiLime team helped with building a platform that is easy to manage and cost-effective for users.
      </>
    ),
  },

  buttonsInFewRowsProps: {
    allButtonsData: [
      {
        buttonsInRowData: [
          { text: 'Infrastructure design and implementation' },
          { text: 'IT support' },
          { text: 'IT advice' },
        ],
        higherRow: true,
      },
    ],
  },

  techStackWithDiagramProps: {
    subtitle:
      'As Neptune runs on Kubernetes, the whole process of establishing and closing VMs becomes seamless and smooth.\n',
    pointListIntro: 'Using Helm templates and a custom scaling service:',
    pointList: [
      'Neptune was able to reduce the time needed to run new machines and finally launch the whole experiment.',
      'Neptune ensured that all VMs shared access to the training dataset being used, making experimentation faster and providing non-trivial cost optimizations.',
      'Using Kubernetes’ granular access control, Neptune.ai was able to guarantee that only authorized workloads had access to their data.',
      'The usage of Kubernetes makes Neptune infrastructure-agnostic, so it can be established in both a private or public cloud.',
    ],
    slides: [
      ansible,
      kubernetes,
      docker,
      aws,
      debian,
      elasticStack,
      googleCloud,
      python,
      etcd,
      git,
      terraform,
      helm,
      ubuntu,
      typeScript,
      java,
      redux,
      react,
      javaScript,
      less,
      mooseFS,
      rxJS,
    ],
  },

  bannerWithDownloadButtonProps: {
    buttonHref:
      'https://resources.codilime.com/hubfs/White%20papers%20and%20case%20studies/Case%20studies%202023/Case%20study_Neptune.ai.pdf',
  },
};
