import { paths } from '@src/constants/pathsEnum';
import { featuredImageSwift } from '@images/case-studies-pages';
import {
  python,
  rust,
  bash,
  git,
  cPlusPlus,
  gitHubActions,
  jenkins,
  cMake,
  bazel,
  aws,
  terraform,
  kubernetes,
} from '@images/logos-techstack-bigger';
import swift from '@images/logos-partners/swift2.png';

export const swiftProps = {
  SEOProps: {
    title: 'Case Study | Accelerating Development And CI/CD Environments',
    description:
      'Discover how we helped Swift Navigation accelerate software development and CI/CD environments. We collaborated to provide expertise in key areas.',
    featuredImage: `https://codilime.com${featuredImageSwift}`,
  },

  headerProps: {
    title: 'Accelerating software development and CI/CD environments',
  },

  sectionUnderHeaderProps: {
    text: 'Swift Navigation is a San Francisco-based tech company with a mission to build a safer future by changing the way we navigate. They have created a platform for precise positioning that utilizes APIs to gather location data with centimeter-level accuracy.',
    logo: {
      src: swift,
      alt: 'Swift Navigation',
    },
  },

  singleTestimonialProps: {
    testimonialCompany: 'Swift',
  },

  expandableTextLinesBlocksProps: {
    subtitle:
      'Swift Navigation’s development and CI/CD environments had significant potential for improvement to increase their operational efficiency. However, their internal teams were stretched thin with competing priorities and lacked the necessary bandwidth to tackle these projects.',
    readMoreText:
      'We collaborated with Swift Navigation to expand their team and provided expertise in areas where they lacked resources. This allowed the client to take on projects that were previously outside their capacity and gave them the flexibility to adjust their team composition as the scope of work evolved.',
  },

  buttonsInFewRowsProps: {
    allButtonsData: [
      {
        buttonsInRowData: [
          { text: 'DevOps services', href: paths.SERVICES.DEVOPS },
          { text: 'Software Development services', href: paths.SERVICES.SOFTWARE_DEVELOPMENT },
        ],
      },
    ],
  },

  techStackWithDiagramProps: {
    subtitle:
      'CodiLime collaborated with the client to improve their development and CI/CD environments to enhance operational efficiency.\n',
    pointListIntro: 'The project involved the following activities:',
    pointList: [
      'Support in analysis of client requirements and development of a plan for implementing the necessary changes.',
      'Implementation of changes to the development and CI/CD environments, including the introduction of new technologies and automation tools to streamline processes.',
      'Integration of the new environment with existing systems and workflows.',
      'Testing and quality assurance of the new environment to ensure optimal performance and reliability.',
    ],
    slides: [python, rust, bash, git, cPlusPlus, gitHubActions, jenkins, cMake, bazel, aws, terraform, kubernetes],
  },

  bannerWithDownloadButtonProps: {
    buttonHref: 'https://resources.codilime.com/hubfs/case-studies/2023/case-study-swift-navigation.pdf',
  },
};
