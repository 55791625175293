// extracted by mini-css-extract-plugin
export var buttonTextContainer = "expandable-text-lines-blocks-module--button-text-container--98642";
export var extraDescription = "expandable-text-lines-blocks-module--extra-description--338d9";
export var hideElement = "expandable-text-lines-blocks-module--hide-element--fe3d8";
export var innerWrapper = "expandable-text-lines-blocks-module--inner-wrapper--5ccc0";
export var readMoreButton = "expandable-text-lines-blocks-module--read-more-button--43399";
export var row = "expandable-text-lines-blocks-module--row--31fc6";
export var section = "expandable-text-lines-blocks-module--section--ae1d3";
export var sectionBackground = "expandable-text-lines-blocks-module--section-background--226b2";
export var sectionContent = "expandable-text-lines-blocks-module--section-content--36780";
export var sectionWithoutDiagram = "expandable-text-lines-blocks-module--section-without-diagram--f250d";
export var subtitle = "expandable-text-lines-blocks-module--subtitle--b4f44";
export var title = "expandable-text-lines-blocks-module--title--d2700";
export var titlesContainer = "expandable-text-lines-blocks-module--titles-container--44dc4";